import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataInitialValueServiceService {
  Parse<T>(elementRef: ElementRef): T {
    return JSON.parse(
      elementRef.nativeElement.getAttribute('data-initial-value')
    ) as T;
  }
}
